import React, { useRef, useEffect, useCallback } from "react";
import { PivotGrid as DynamicGrid, FieldChooser, Search, HeaderFilter, FieldPanel, } from 'devextreme-react/pivot-grid';
import Chart, { AdaptiveLayout, CommonSeriesSettings, Size, Tooltip, } from 'devextreme-react/chart';

const PowerReport = ({
    dataSource,
    showChart,
    showFieldChooser,
    title
}) => {
    const chartRef = useRef(null);
    const powerReportRef = useRef(null);

    useEffect(() => {
        if (showChart) {
            powerReportRef.current.instance().bindChart(chartRef.current.instance(), {
                dataFieldsDisplayMode: 'splitPanes',
                alternateDataFields: false,
            });
            chartRef.current.instance().option({
                tooltip: {
                    enabled: true,
                    customizeTooltip: function (arg, brg, crg) {
                        // Access PivotGrid data source to get fields
                        const splitSeries = arg.seriesName.split('|');
                        const caption = splitSeries[splitSeries.length - 1].trim();
                        const pivotGridDataSource = powerReportRef.current.instance().getDataSource();
                        const fields = pivotGridDataSource.getAreaFields('data', true);

                        // Find the field corresponding to this value
                        const field = fields.find(f => f.caption === caption);
                        console.log("arg", arg);
                        console.log("fields - field - caption - splitSeries", fields, field, caption, splitSeries);
                        if (field && field.format) {
                            return {
                                text: new Intl.NumberFormat('nl-NL', field.format).format(arg.originalValue),
                            };
                        } else {
                            // Default behavior if no specific format is found
                            return {
                                text: arg.valueText,
                            };
                        }
                    },
                },
            });
        }
    }, [dataSource, showChart]);

    const handleCellPrepared = useCallback(async ({ cell, area, cellElement, component }) => {
        const displayedFields = component.getDataSource().getAreaFields('row')
        if (area === 'row' && cell.type === 'D') {
            const displayedFields = component.getDataSource().getAreaFields('row')
            const rowPathLength = cell.path.length;
            const field = displayedFields[rowPathLength - 1]
            if (field.detailviewId == null)
                return;

            const ddds = component.getDataSource().createDrillDownDataSource()
            ddds.paginate(false);
            const data = await ddds.load();
            const recordsOfClickedValue = data.filter(rec => rec[field.dataField] === cell.path[cell.path.length - 1]);
            const detailviewName = field.detailviewName;
            const recordId = recordsOfClickedValue.map(rec => rec[field.keyPropertyName?.toLowerCase()])[0];
            cellElement.innerHTML = `<div className="powerreportlabel">${cell.text}<a href="../detail/${field.detailviewId}/${recordId}" target="_blank"> <span class="powerreporticon dx-icon-arrowright" tabindex="0" aria-label="Show filter options for column 'undefined'" aria-haspopup="dialog" role="button"></span></a></div>`;
        }
    }, [])

    const handleFieldChooserOptionChanged = useCallback((e) => { console.log("handleFieldChooserOptionChanged", e); }, []);

    return (<>
        <header className="bloom-list-header">
            <h3 className="listHeader">{`${title}`}</h3>

        </header>
        {showChart &&
            <>
                <Chart ref={chartRef}>
                    <Size height={300} />
                    <Tooltip
                        enabled={showChart}
                    />
                    <CommonSeriesSettings type="bar" />
                    <AdaptiveLayout width={450} />
                </Chart>
            </>
        }

        <DynamicGrid
            id="dynamicgrid"
            dataSource={dataSource}
            allowSorting={true}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={true}
            showColumnGrandTotals={true}
            showRowTotals={true}
            allowExpandAll={true}
            allowSearch={true}

            showRowGrandTotals={true}
            ref={powerReportRef}
            onCellPrepared={handleCellPrepared}

        >
            <HeaderFilter
                showRelevantValues={true}
            >
                <Search enabled={true} />
            </HeaderFilter>
            {showFieldChooser && <FieldChooser
                allowSearch={true}
                onContentReady={handleFieldChooserOptionChanged}
            />}
            <FieldPanel visible={true} />
        </DynamicGrid></>)
};
export default PowerReport;