import TEXT from './TEXT';
import NUMBER from './NUMBER';
import DATE from './DATE';
import TEXTAREA from './TEXTAREA';
import CHECKBOX from './CHECKBOX';
import HTMLVIEWER from './HTMLVIEWER';
import HTMLEDITOR from './HTMLEDITOR';
import HTMLEDITORADVANCED from './HTMLEDITORADVANCED';
import COLOR from './COLOR';
import AUTONUMMERING from "../properties/AUTONUMMERING";
import YESNO from "../properties/YESNO";
import CALCULATED from './CALCULATED';
import MULTIPLESELECT from './MULTIPLESELECT';
import FILE from './FILE';
import ACTION from './ACTION';
import AVEV from './AVEV';
import SIGNATURE from './SIGNATURE';
import GUID from './GUID';
import VOID from './VOID';
import FLOWCHART from './FLOWCHART';
import SCHEDULER from './SCHEDULER';
import SL from './SL';
import { ColumnDisplayValueMemo } from "../../enums/columnDisplayValue";
const getControl = (
    args = {},
    overrides = {}, // used by AVEV
) => {
    const {
        column,
        index,
        columnIndex,
        rowIndex,
        detailviewId,
        tableitemId,
        controlPropertyId,
        registerLoaded,
        handleControlUpdate,
        getValueByColumnId,
        getColumnByColumnId,
        getValueByColumnName,
        allDetailviewBlocksAreLoaded,
        changeInForm,
        forceReload,
    } = args;
    const {
        action,
        columnTypeCode,
        //columnTypeCodeFromColumnType,
        id,
        defaultValue,
        displayFormat,
        //displayValueFromColumnType,
        type,
        extraField1,
        options,
        option,
        inputTableId,
        inputListId,
        cascadingParentColumnId,
        table,
        dependColumnId,
        dependParentColumnId,
        inputDisplayColumnId,
        inputType,
        httpFilePath,
        regex,
        memoField,
        displayValueId,
        columnValidationId,
        thumbnailLarge,
        typeAheadCharacters,
        groupColumnId,
        imageLinkColumnId,
    } = column;
    const readonly = overrides.readonly || column.readonly;
    const mandatory = overrides.mandatory || column.mandatory;
    const alias = overrides.alias || column.alias;
    const value = Object.hasOwn(overrides, 'value') ? overrides.value : column.value; // we do this to support `null`
    const values = Object.hasOwn(overrides, 'values') ? overrides.values : column.values; // we do this to support `null`
    const matchesTheRegex = typeof value !== 'string' || !regex ? true : (value || "").match(regex);
    
    switch (columnTypeCode) {
        case "GUID":
            return (
                <GUID
                    key={id}
                    label={alias}
                    value={value}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    columnId={id}
                    mandatory={mandatory}
                    readonly={readonly}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "SIGNATURE":
            return (
                <SIGNATURE
                    key={id}
                    label={alias}
                    value={value}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    columnId={id}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "COMPOSITE":
            return (
                <CALCULATED
                    asString
                    key={id}
                    value={value}
                    defaultValue={defaultValue}
                    alias={alias}
                    displayFormat={displayFormat}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    columnId={id}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    getValueByColumnName={getValueByColumnName}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    columnTypeCode={columnTypeCode}

                />
            );
        case "CALCULATED":
            return (
                <CALCULATED
                    key={id}
                    value={value}
                    defaultValue={defaultValue}
                    alias={alias}
                    displayFormat={displayFormat}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    columnId={id}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    getValueByColumnName={getValueByColumnName}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    columnTypeCode={columnTypeCode}
                />
            );
        case "TXT":
        case "EMAIL":
            return (
                <TEXT
                    key={id}
                    label={alias}
                    value={value}
                    matchesTheRegex={matchesTheRegex}
                    displayFormat={displayFormat}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    type={type}
                    readonly={readonly}
                    columnId={id}
                    columnTypeCode={columnTypeCode}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    alwaysShowEmailButton={columnTypeCode === 'EMAIL'}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    regex={regex}
                />
            );
        case "DEC":     // !TODO: why does EU return DEC
            // Should be fixed for new columns, old ones still need to be scripted to the new style

            return (
                <NUMBER
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    displayFormat={displayFormat}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    type={type}
                    readonly={readonly}
                    columnId={id}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "NUM":
            return (
                <NUMBER
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    displayFormat={displayFormat ?? "0"}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    type={type}
                    columnId={id}
                    readonly={readonly}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "EU":
            return (
                <NUMBER
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    displayFormat={displayFormat}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    type={type}
                    readonly={readonly}
                    columnId={id}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    isEuro={true}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    columnValidationId={columnValidationId}
                />
            )
        case "PERC":
            return (
                <NUMBER
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    //displayFormat={"2"} //@TODO deze moet nog aan het percentageveld zelf worden gekoppeld
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    type={type}
                    readonly={readonly}
                    columnId={id}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    isPercentage={true}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            )
        case "D":
            return (
                <DATE
                    key={id}
                    columnId={id}
                    label={alias}
                    value={value}
                    displayFormat={displayFormat}
                    mandatory={mandatory}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    readonly={readonly}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "DT":
            return (
                <DATE
                    type="datetime"
                    key={id}
                    columnId={id}
                    label={alias}
                    value={value}
                    displayFormat={displayFormat}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    readonly={readonly}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "MEMO":
            if (displayValueId === ColumnDisplayValueMemo.Text) {
                return (
                    <TEXTAREA
                        key={id}
                        label={alias}
                        value={value}
                        alias={alias}
                        keyNumber={index}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        height={extraField1}
                        mandatory={mandatory}
                        readonly={readonly}
                        columnId={id}
                        registerLoaded={registerLoaded}
                        onControlUpdate={handleControlUpdate}
                        memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    />
                );
            } else if (displayValueId === ColumnDisplayValueMemo.Flowchart) {
                return (
                    <FLOWCHART
                        key={id}
                        label={alias}
                        value={value}
                        alias={alias}
                        keyNumber={index}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        height={extraField1}
                        mandatory={mandatory}
                        columnId={id}
                        registerLoaded={registerLoaded}
                        onControlUpdate={handleControlUpdate}
                        memoField={memoField == null ? null : decodeURIComponent(memoField)}
                        readOnly={readonly}
                    />
                );
            } else if (displayValueId === ColumnDisplayValueMemo.Scheduler) {
                return (
                    <SCHEDULER
                        key={id}
                        label={alias}
                        value={value}
                        alias={alias}
                        keyNumber={index}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        height={extraField1}
                        mandatory={mandatory}
                        columnId={id}
                        registerLoaded={registerLoaded}
                        onControlUpdate={handleControlUpdate}
                        memoField={memoField == null ? null : decodeURIComponent(memoField)}
                        readOnly={readonly}
                    />
                );
            } else {
                break;
            }

        case "HTMLEDITOR":
            return (
                <HTMLEDITOR
                    key={id}
                    label={alias}
                    value={value}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    readonly={readonly}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    columnId={id}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    mandatory={mandatory}

                />
            );
        case "HTMLEDITORADVANCED":
            return (
                <HTMLEDITORADVANCED
                    key={id}
                    label={alias}
                    value={value}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    readonly={readonly}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    columnId={id}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    mandatory={mandatory}

                />
            );
        case "JN":
            return (
                <YESNO
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    readonly={readonly}
                    columnId={id}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "AU":
            return (
                <CHECKBOX
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    readonly={readonly}
                    columnId={id}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        case "HTMLVIEWER":
            return (
                <HTMLVIEWER
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    readonly={readonly}
                    columnId={id}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        /*case "KL":
            return (
                <SELECT
                    key={id}
                    id={id}
                    label={alias}
                    value={value}
                    options={options}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    readonly={readonly}
                    columnId={id}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    inputTableId={inputTableId}
                    inputDisplayColumnId={inputDisplayColumnId}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );*/
        /*case "USRS":
            return (
                <SELECT
                    key={id}
                    id={id}
                    label={alias}
                    value={value}
                    options={options}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    readonly={readonly}
                    columnId={id}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    inputDisplayColumnId={inputDisplayColumnId}
                    showUserIcon={true}
                    showGoToDetailview={false}
                    orderByValue={true}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            )*/
        case "COLOR":
            return (
                <COLOR
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    readonly={readonly}
                    columnId={id}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );

        case "MULTIPLEKEUZELIJST":
            return (
                <MULTIPLESELECT
                    key={id}
                    label={alias}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    readonly={readonly}
                    options={options}
                    values={values}
                    columnId={id}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            );
        /*case "ZKL":
        case "AVAKZ":
            return (
                <SELECTSEARCH
                    key={id}
                    label={alias}
                    value={value}
                    alias={alias}
                    keyNumber={index}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    mandatory={mandatory}
                    readonly={readonly}
                    columnId={id}
                    option={option}
                    inputListId={inputListId}
                    inputTableId={inputTableId}
                    inputDisplayColumnId={inputDisplayColumnId}
                    detailviewId={detailviewId}
                    controlPropertyId={controlPropertyId}
                    cascadingParentColumnId={cascadingParentColumnId}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    getColumnByColumnId={getColumnByColumnId}
                    getValueByColumnId={getValueByColumnId}
                    allDetailviewBlocksAreLoaded={allDetailviewBlocksAreLoaded}
                    withMagnifyingGlass={columnTypeCode !== 'AVAKZ'} // @TODO: fix the magnifying glass feature for AVAKZ
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            )*/
        case "AUTONUMMERING":
            return (
                <AUTONUMMERING
                    key={id}
                    value={value}
                    columnId={id}
                    label={alias}
                    registerLoaded={registerLoaded}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            )
        /*case "AVAK":

            return (
                <AVAK
                    key={id}
                    label={alias}
                    columnId={id}
                    value={value}
                    detailviewId={detailviewId}
                    controlPropertyId={controlPropertyId}
                    inputTableId={inputTableId}
                    inputDisplayColumnId={inputDisplayColumnId}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    cascadingParentColumnId={cascadingParentColumnId}
                    getValueByColumnId={getValueByColumnId}
                    getColumnByColumnId={getColumnByColumnId}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    readOnly={readonly}
                />
            )*/
        case "AVEV":
            return (
                <AVEV
                    key={id}
                    label={alias}
                    columnId={id}
                    value={value}
                    readonly={readonly}
                    mandatory={mandatory}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    getValueByColumnId={getValueByColumnId}
                    dependColumnId={dependColumnId}
                    dependParentColumnId={dependParentColumnId}
                    args={args}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            )
        case "FILE":
            return (
                <FILE
                    key={id}
                    label={alias}
                    columnId={id}
                    value={value}
                    mandatory={mandatory}
                    readonly={readonly}
                    clickOnImageOpensPreview={false}
                    openExternalInsteadOfDownload={false}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    httpFilePath={httpFilePath}
                    ftp={false}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    thumbnailLarge={thumbnailLarge}
                />
            )
        case "ACTION":
            return (
                <ACTION
                    key={id}
                    label={alias}
                    columnId={id}
                    value={value}
                    action={action}
                    table={table}
                    tableitemId={tableitemId}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    changeInForm={changeInForm}
                    forceReload={forceReload}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                />
            )
        case "FTP":
            return (
                <FILE
                    key={id}
                    label={alias}
                    columnId={id}
                    value={value}
                    clickOnImageOpensPreview={false}
                    openExternalInsteadOfDownload={false}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    httpFilePath={httpFilePath}
                    ftp={true}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    thumbnailLarge={thumbnailLarge}
                />
            )
        case "USRS":
        case "SELECT":
            return (
                <SL
                    label={alias}
                    value={ value == null || value === "" ? null : parseInt(value)}
                    mandatory={mandatory}
                    readonly={readonly}
                    columnId={id}
                    option={option}
                    inputListId={inputListId}
                    inputTableId={inputTableId}
                    inputDisplayColumnId={inputDisplayColumnId}
                    detailviewId={detailviewId}
                    controlPropertyId={controlPropertyId}
                    cascadingParentColumnId={cascadingParentColumnId}
                    registerLoaded={registerLoaded}
                    onControlUpdate={handleControlUpdate}
                    getColumnByColumnId={getColumnByColumnId}
                    memoField={memoField == null ? null : decodeURIComponent(memoField)}
                    typeAheadCharacters={typeAheadCharacters}
                    getValueByColumnId={getValueByColumnId}
                    columnHasGroup={groupColumnId != null}
                    columnHasImage={imageLinkColumnId != null}
                />
            )
        case 'Id':
        case null: {
            if (inputType.name === "NONE") {
                // this is ID, probably
                return false;
            }
            return <VOID label={alias} placeholder="Onbekend veld" />;
        }

        default:
            console.warn(`[getControl] Did not find a component for columnTypeCode ${columnTypeCode}`);
            return null;
    }
}

export default getControl;
