import React, { memo, useState, useEffect, useMemo, useCallback, Children } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import DataSource from "devextreme/data/data_source";
import { Lookup } from "devextreme-react";
import { DropDownOptions } from "devextreme-react/lookup";
import CustomStore from "devextreme/data/custom_store";
import userPlaceholder from "../../../src/assets/images/userPlaceholder.png";
import imagePlaceholder from "../../../src/assets/images/landscape-placeholder.svg";
import { Tooltip } from 'devextreme-react/tooltip';
import "../../components/list/select-search.scss";


const SelectionList = ({
    columnId,
    initialValue,
    typeAheadCharacters,
    label,
    readonly,
    cascadingParentItemId,
    onSelectionChanged,
    deprecatedOption,
    columnHasGroup,
    columnHasImage,
    setCustomLabel
//setCustomLabel(selection.selectedItem?.infoLabel);
}) => {
    // console.log("[RENDER] SelectionList", label, initialValue, typeof initialValue);
    const itemRender = useCallback((itemData) => {
        const color = itemData.color;
        return (
            <div
                className="select-search-color-container lookup-color"

            >

                {Boolean(columnHasImage) &&
                    <div className="profilepic-container">
                        {Boolean(columnHasImage) &&
                            <img
                                className={"profilepic"}
                                src={itemData.thumbnailSmall ? (/* 'data:image/png;base64,' + */ itemData.thumbnailSmall) : imagePlaceholder}
                                alt="Foto"
                                style={{ border: `2px solid ${itemData.color ? itemData.color : 'transparent'}` }} />
                        }

                    </div>
                }
                {Boolean(color) && Boolean(columnHasImage) === false && (
                    <i
                        className="fas fa-square coloricon"
                        style={{
                            color,
                        }}
                    />
                )}
                <p className="color-labeltext">{itemData.label}</p>
            </div>
        );
    }, [columnHasImage]);

    const loadDatasourceSelectionList = useCallback(async (loadOptions) => {
        // console.log("[SelectionList] loadDatasourceSelectionList - loadOptions", loadOptions);
        const url = `${appSettings.api.endpoint}/api/0/property/${columnId}/options`;
        try {
            const res = await msalFetch(null, url,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({
                        columnId: columnId,
                        searchValue: loadOptions.searchValue,
                        cascadingParentItemId,
                    }),
                }
            );

            const data = await res.json();
            if (columnHasGroup) {
                const uniqueHeaders = Array.from(new Set(data.map(item => item.groupHeaderName)));
                const results = uniqueHeaders.map(header => { return { key: header, items: data.filter(rec => rec.groupHeaderName === header) } });
                // console.log("[SL] results", results);
                return results;
            } else {
                // console.log("[SL] results", data);
                return data;
            }
        } catch (error) {
            throw error;
        }
    }, [columnId, cascadingParentItemId, columnHasGroup]);




    const getItemByKey = useCallback(async (key) => {

        const url = `${appSettings.api.endpoint}/api/0/property/${columnId}/option`;
        try {
            const res = await msalFetch(null, url,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({
                        columnId: columnId,
                        value: key,
                    }),
                }
            );
            const data = await res.json();
            const result = { ...data, value: parseInt(data.value) };
            setCustomLabel(result.infoLabel);
            return result;
        } catch (error) {
            throw error;
        }
    }, [columnId, setCustomLabel]);

    const datasource = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'value',
            load: loadDatasourceSelectionList,
            byKey: getItemByKey
        }),
        pageSize: 100,
        paginate: true
    }), [loadDatasourceSelectionList, getItemByKey]);

    return <>

        <Lookup
            className="select-search-lookup"
            dataSource={datasource}
            value={initialValue}
            showCancelButton={false}
            showClearButton={true}
            noDataText="Geen resultaat"
            displayExpr="label"
            valueExpr="value"
            minSearchLength={typeAheadCharacters}
            searchEnabled={true}
            cleanSearchOnOpening={true}
            searchPlaceholder={typeAheadCharacters == null ? `${label} zoeken` : `${label} zoeken: (typ minimaal ${typeAheadCharacters} teken${typeAheadCharacters === 1 ? '' : 's'})`}
            readOnly={readonly}
            stylingMode={readonly ? "filled" : "outlined"}
            grouped={columnHasGroup}
            itemRender={itemRender}
            onSelectionChanged={onSelectionChanged}
            fieldRender={fieldRender({ deprecatedOption, columnId, isDetailview: true, columnHasImage })}
        >
            <DropDownOptions
                maxHeight="60vh"
                showTitle={true}
                showCloseButton
                hideOnOutsideClick={true}
                minWidth="350px"
                title={`Selecteer ${label.toLowerCase()}`}
            />
        </Lookup>
    </>
    //SLrep

}

export const fieldRender = ({
    deprecatedOption,
    columnId,
    isOverview = false,
    isDetailview = false,
    isScheduler = false,
    columnHasImage = false
}) => (itemData) => {
    if (itemData == null) {
        return
    }
    if (itemData == null && deprecatedOption == null) {
        return (<div className="select-search-color-container">
            <p className="placeholder" >{`Kiezen...`}</p>
        </div>)
    }
    if (itemData == null && deprecatedOption != null) {
        return (<div
            className="select-search-color-container"
        >
            <p title={deprecatedOption ? `${deprecatedOption.value} is de huidige waarde, maar is niet meer beschikbaar als keuze` : "Kiezen..."} className={deprecatedOption ? "deprecated" : "placeholder"} >
                {`${deprecatedOption ? deprecatedOption.value : "Kiezen..."}`}</p>
        </div>)
    }

    return (

        < div

            className={`select-search-color-container`
            }
        >
            {Boolean(columnHasImage) &&
                (
                    <div className={`profilepic-container ${isOverview ? "overviewpic" : ""}`}>
                        <Tooltip
                            target={`#closeup_${columnId}_${itemData.value}`}
                            showEvent="mouseenter"
                            hideEvent="mouseleave"
                            position={isOverview ? "left" : "top"}
                            hideOnOutsideClick={false}
                        >
                            <div id={`closeup_${columnId}_${itemData.value}`} className="bloom-footer-tooltip">

                                <img
                                    src={itemData.thumbnailSmall ? (itemData.thumbnailSmall.startsWith('data') ? itemData.thumbnailSmall : 'data:image/png;base64,' + itemData.thumbnailSmall) : imagePlaceholder}
                                    alt="Closeup"
                                    className="closeup"
                                    style={{ border: `4px solid ${itemData.color ? itemData.color : 'transparent'}` }}
                                ></img><br></br>
                                <strong>{itemData.label}</strong>

                            </div>
                        </Tooltip>
                        <img
                            id={`closeup_${columnId}_${itemData.value}`}
                            className={"profilepic"}
                            src={itemData.thumbnailSmall ? (itemData.thumbnailSmall.startsWith('data') ? itemData.thumbnailSmall : 'data:image/png;base64,' + itemData.thumbnailSmall) : imagePlaceholder}
                            alt="Foto"
                            width={"32"}
                            height={"32"}
                            style={{
                                border: `2px solid ${itemData.color ? itemData.color : 'transparent'}`
                            }} />
                    </div>
                )}
            {
                Boolean(itemData.color) && Boolean(columnHasImage) === false && (
                    <i
                        className="fas fa-square coloricon"
                        style={{ color: itemData.color }} // Use 'color' or another relevant CSS property
                    />
                )
            }

            <p
                className={`color-labeltext ${itemData.deleted && 'deleted'} ${itemData.deprecated && 'deprecated'}`}
                data-html="true"
                title={`${itemData.label} ${itemData.deprecated !== true ?
                    ''
                    :
                    '- niet als optie meer beschikbaar'}`} >
                {itemData.label} {itemData.deleted === true && '(verwijderd)'}{itemData.deprecated === true && '(huidige waarde)'}
            </p>


        </div >
    );
};

export default memo(SelectionList);
