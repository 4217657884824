import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useRef } from "react";
import appSettings from "../../../appsettings";
import { useParams, useHistory } from "react-router-dom";
import msalFetch from "../../../api/MsalFetch.js";
import { TextBox } from "devextreme-react";
import { Col, Row } from "react-bootstrap";
import { useModalContext } from "../../../context/modalContext";
import validatedIcons from "../../modals/editMenuConfig/iconsSelect/validatedIcons";

import DataGrid, {
    Sorting,
    Column,
    Pager,
    Paging,
    RowDragging,
} from "devextreme-react/data-grid";
import BloomFooter from "../../footer/BloomFooter";
import ConfigPageControls from "../parts/ConfigPageControls";
import Loading from "../../loading/Loading";
import toast from "react-hot-toast";
import IconSelect from "../../modals/editMenuConfig/iconsSelect/IconsSelect";

const MenuSingle = () => {
    const msal = useMsal();
    const [selectedIcon, setSelectedIcon] = useState("");
    const {
        setIsEditing,
        isEditing,
        setModal,
        setModalContent,
        setNotificationData,

        setDeleteProceedNotification,
        deleteProceedNotification,
        setFormInValidNotification,
    } = useModalContext();
    const [initialData, setInitialData] = useState({});

    const [loading, setLoading] = useState(true);
    const [formValue, setFormValue] = useState({});
    const [changeInForm, setChangeInForm] = useState(false);
    const dataGridRef = useRef();
    const history = useHistory();

    const { id } = useParams();

    const checkChanges = (e, mandatorycheck) => {
        if (!mandatorycheck) {
            if (e) {
                if (e.value.trim() !== "") {
                    e.element.classList.remove("bl-mandatory-field");
                } else {
                    e.element.classList.add("bl-mandatory-field");
                }
            }
        }

        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const handleDeleteMenuClick = (data) => {
        const base = `${appSettings.api.endpoint}/api/configurator/menuItem/menuItems/`;
        const targetId = data.data.id;
        setDeleteProceedNotification(true);
        setNotificationData({ deleteUrl: `${base}${targetId}` });
    };

    const handleEditMenuClick = (data) => {
        setIsEditing(true);
        setModal("EDITMENUCONFIG");
        setModalContent({ parentData: data, isCreate: false });
    };

    const handleNewMenuClick = (itemData) => {
        setIsEditing(true);
        setModal("NEWMENUCONFIG");
        setModalContent({ parentData: {}, isCreate: true });
    };
    let tempMenuData = [];
    const getData = async () => {
        setLoading(true);
        // om de titel van de pagina op te kunnen halen
        msalFetch(msal, `${appSettings.api.endpoint}/api/configurator/menuItem/${id}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setFormValue(data);
                setSelectedIcon(data.icon);
                setInitialData(data);
                setLoading(false);
                return;
            })
            .catch(() => {
                setLoading(false);
                throw "Data Loading Error";
            });
    };
    useEffect(() => {
        getData();
    }, [isEditing, deleteProceedNotification]);

    // useEffect(() => {
    //   getData();
    // }, []);

    const handleRowDrag = (e) => {
        console.log("dragged");
        console.log(e);

        let tempFormValue = formValue;
        tempFormValue.menuItems.sort((a, b) => {
            return a.sort - b.sort;
        });

        tempFormValue.menuItems.splice(e.fromIndex, 1);
        tempFormValue.menuItems.splice(e.toIndex, 0, e.itemData);

        console.log(tempFormValue);
        let tempMenuItems = [];
        tempFormValue.menuItems.map((item, index) => {
            let tempItem = item;
            tempItem["sort"] = index;

            return (tempMenuItems = [...tempMenuItems, tempItem]);
        });

        tempMenuItems.sort((a, b) => {
            return a.sort - b.sort;
        });

        tempFormValue["menuItems"] = tempMenuItems;
        setFormValue(tempFormValue);
        dataGridRef.current.instance().refresh();
        console.log(tempFormValue);
        checkChanges();
    };

    const hiddenColumn = (data) => {
        return <div className="hiddenColumn" />;
    };
    const addNewMenuItem = (data) => {
        setIsEditing(true);
        setModal("EDITMENUCONFIG");
        setModalContent({ parentData: {}, isCreate: true, menuId: id });
    };

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={addNewMenuItem}
                    title="Nieuw menu item toevoegen"
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }

    const handleSaveClick = async () => {
        console.log("saven maar");

        let allDocument = document.getElementsByClassName("bl-mandatory-field");

        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);
            return null;
        }

        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/menuItem/?id=${id}`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formValue),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                toast.success("De gegevens zijn successvol opgeslagen...");
            } else {
                toast.error("Oeps er ging iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    const handleNameValueChange = (e) => {
        console.log(formValue.name);
        let tempFormValue = formValue;
        console.log(tempFormValue.name);
        tempFormValue["name"] = e.value;
        console.log(tempFormValue);

        setFormValue(tempFormValue);
        checkChanges(e);
    };

    const displayMenuItemsName = (itemData) => {
        const { data: item } = itemData;
        return `${item.name} - ${item.overviewName ?? item.schedulerName ?? item.powerReportName} ${item.TableName != null ? `(${item.tableName})` : ''}`;
    };

    return (
        <main className="bl-content bl-config-menu-page">
            {loading && <Loading />}
            {!loading && (
                <>
                    <header className="bl-config-page-header">
                        {" "}
                        <h1>{formValue.name}</h1>{" "}
                        <ConfigPageControls
                            saveFunction={handleSaveClick}
                            changeInForm={changeInForm}
                            hasExitFunction={true}
                            exitPageFunction={() => history.push("/configurator/menu")}
                        />
                    </header>

                    <div>
                        <Row>
                            <Col lg="6" md="12" className="bl-card bl-form-heading col-6">
                                <h3>Menu</h3>
                                <div className="dx-field">
                                    <div className="dx-field-label">Menu naam</div>
                                    <div className="dx-field-value">
                                        <TextBox
                                            defaultValue={formValue.name}
                                            valueChangeEvent="keyup"
                                            onValueChanged={handleNameValueChange}
                                            className={`${formValue.name.trim() === "" ? "bl-mandatory-field" : ""
                                                }`}
                                        />
                                    </div>
                                </div>

                                <div className="dx-field">
                                    <div className="dx-field-label menu ">
                                        Menu icoon{" "}
                                        <div className="bl-icon-preview">
                                            <i className={`fas ${selectedIcon}`} />
                                        </div>
                                    </div>
                                    <div className="dx-field-value">
                                        <IconSelect
                                            mandatory={false}
                                            mandatoryCheck={false}
                                            loading={false}
                                            dataSource={validatedIcons}
                                            handleValueChange={(e) => {
                                                let tempFormValue = formValue;
                                                tempFormValue["icon"] = e.value;
                                                setFormValue(tempFormValue);
                                                checkChanges(e, true);
                                                setSelectedIcon(e.value);
                                            }}
                                            defaultValue={formValue.icon}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="bl-card">
                            <header className="menu-single-header">
                                <h3>Menu-items</h3>
                            </header>
                            <DataGrid
                                dataSource={formValue.menuItems}
                                showBorders={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                showColumnLines={false}
                                ref={dataGridRef}
                                columnAutoWidth={true}
                                allowSorting={false}
                            >
                                <Column
                                    dataField="name"
                                    cellRender={displayMenuItemsName}
                                    // width={auto}
                                    dataType="string"
                                    allowSorting={false}
                                    caption=""
                                />

                                <Column
                                    cellRender={cellRender}
                                    allowSorting={false}
                                    headerCellRender={addNewButton}
                                    alignment="center"
                                    width={80}
                                />

                                <Sorting mode="multiple" />
                                <Pager visible={false} />
                                <RowDragging allowReordering={true} onReorder={handleRowDrag} />

                                <Paging defaultPageSize={1000} />
                            </DataGrid>
                        </Row>
                    </div>

                    <BloomFooter />
                </>
            )}
        </main>
    );

    function cellRender(item) {
        // console.log(item);
        return (
            <div className="bl-column-controls">
                <i
                    className="fas fa-pencil-alt  bl-column-control-btn bl-column-edit-btn"
                    onClick={() => handleEditMenuClick(item)}
                />

                <i
                    className="fas fa-trash-alt  bl-column-control-btn bl-column-delete-btn"
                    onClick={() => handleDeleteMenuClick(item)}
                />
            </div>
        );
    }
};

export default MenuSingle;
