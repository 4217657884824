import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import appSettings from "../../appsettings";
import "./Detailview.scss";
import msalFetch from "../../api/MsalFetch.js";
import { Container, Row } from "react-bootstrap";
import DetailViewBlock from "./DetailViewBlock";
import FallbackBlock from "./FallbackBlock";
import ImageBlock from "./ImageBlock";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Tooltip } from 'devextreme-react/tooltip';
import RelationBlock from "./RelationBlock";
import TabBlock from "./TabBlock";
import PoweredByBloom from "../footer/PoweredByBloom";
import Loading from "../loading/Loading";
import DeleteItem from "../notifications/userLeaveNotifications/notifications/DeleteItem";
import DetailviewTabs from "./DetailviewTabs";
import LOGGING from "../modals/logging/LOGGING";
import DetailviewInfoControls from "./DetailviewInfoControls";
import DetailviewControlsNew from "./DetailviewControlsNew";
import UserLeave from "../notifications/userLeaveNotifications/UserLeave";
import LocalFormInValidNotification from "../notifications/LocalFormInvalidNotification";
import { useDefaultValueForColumn, convertBackendValueForColumn } from './helpers';
import calculate from '../calculate';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useGraphContext } from "../graph/context";
import FlowchartBlock from "./FlowchartBlock";

import userPlaceholder from "../../../src/assets/images/userPlaceholder.png"
import PowerReportBlock from "./PowerReportBlock.js";
import { ColumnType } from "../../enums/columnType.js";

const Content = ({
    blockHistoryOnDirty = false,
    allDetailviewData = [],
    currentDetailview,
    isMobile,
    isQuickCreate, // @TODO, DataGridSort needs this
    tableitemId,
    isDetailview,
    parentControlRelationId,
    parentTableitemId,
    isQuickEdit,
    isMasterDetail,
    handleMasterDetailSave,
    handleClose,
    handleAddNew,
    handleSave,
    handleSaveAndCreateNew,
    handleUserLeaveCancel,
    handleUserLeaveSave,
    handleUserLeaveContinue,
    handleDelete,
    isContentForModal = false,
    showTitle = true,
    setTitleToHelmet = showTitle,
    showTabs = false,
    instructions,
    connectorStartDate,
    connectorEndDate,
    connectorUserId,
    historyBackOnSave = true,
    refreshGrid,
    draggableSelectFieldId,
    draggableSelectFieldValue
}) => {

    const { id: detailviewId, table } = currentDetailview;
    const tableId = table.id;
    const tableName = table.tableName;
    const logging = table.enableLogging;
    const history = useHistory();
    const { push } = history;
    const isCreate = Boolean(!tableitemId);
    const [footerInfo, setFooterInfo] = useState(null);
    const [openDeleteNotification, setOpendDeleteNotification] = useState(false);
    const [throwNotification, setThrowNotification] = useState(false);
    const [openLogging, setOpenLogging] = useState(false);
    const [invalidFormNotification, setInvalidFormNotification] = useState(false);
    const { userLoading, currentUser } = useGraphContext();
    const getDefaultValueForColumn = useDefaultValueForColumn(currentUser);
    const addColumnsToArray = useCallback((state, columns, { newEntry, forced } = {}) => {
        const newColumns = columns.map(column => {
            let value;
            if (newEntry) {
                if (column.connectorPropertyName === 'StartDate' && connectorStartDate != null) {
                    value = connectorStartDate;
                } else if (column.connectorPropertyName === 'EndDate' && connectorEndDate != null) {
                    value = connectorEndDate;
                } else if (column.connectorPropertyName === 'UserId' && connectorUserId != null) {
                    value = String(connectorUserId);
                } else {
                    if (column.defaultValue) {
                        value = getDefaultValueForColumn(column.defaultValue, column.columnTypeCode);
                    } else {
                        value = convertBackendValueForColumn(column);
                    }
                }
            } else {
                value = convertBackendValueForColumn(column);
            }
            return {
                ...column,
                value: value,
            }
        });

        if (forced) {
            return [
                ...state.filter(({ id }) => !newColumns.some(({ id: idInColumns }) => id === idInColumns)),
                ...newColumns,
            ];
        }
        const columnsNotAlreadyInState = newColumns.filter(({ id }) => !state.some(({ id: idInState }) => id === idInState));

        return [
            ...state,
            ...columnsNotAlreadyInState,
        ];
    }, [connectorStartDate, connectorEndDate, connectorUserId, getDefaultValueForColumn]);
    const [formValue, setFormValue] = useState([]);
    const addColumnsToFormValues = useCallback((columns = [], options) => {
        setFormValue(state => addColumnsToArray(state, columns, options));
    }, [addColumnsToArray]);
    const getColumnByColumnId = useCallback((columnId) => formValue.find((item) => item.id === columnId), [formValue]);
    const getValueByColumnId = useCallback((columnId) => getColumnByColumnId(columnId)?.value, [getColumnByColumnId]);
    const getColumnByColumnName = useCallback((columnName) => formValue.find((item) => item.name === columnName), [formValue]);
    const getValueByColumnName = useCallback((columnName) => getColumnByColumnName(columnName)?.value, [getColumnByColumnName]);
    const emptyMandatoryColumns = formValue.reduce((acc, column) => {
        if (!column.mandatory) {
            return acc;
        }
        if (column.columnTypeCode === 'Id') {
            return acc;
        }
        if (column.inputType.name === "NONE") {
            return acc;
        }
        if (column.value === null || column.value === undefined || column.value === '') {
            return acc.concat(column);
        }
        return acc;
    }, []);
    const nonMatchingRegexColumns = formValue.reduce((acc, column) => {
        // only match TEXT for now
        if (column.columnTypeCode !== 'TXT') {
            return acc;
        }
        if (column.regex) {
            if (column.value && !column.value.match(column.regex)) {
                return acc.concat(column);
            }
        }
        return acc;
    }, []);
    const [forceReloadTimestamp, setForceReleadTimestamp] = useState(undefined);
    const forceReload = useCallback(() => {
        setForceReleadTimestamp(new Date().toISOString());
    }, []);
    const [changeInForm, setChangeInForm] = useState(false);
    const [saving, setSaving] = useState(false);
    const saveDisabled = saving || !changeInForm;

    const [fetchedTitle, setFetchedTitle] = useState(null);
    const { titleColumnId } = currentDetailview;
    // load the titleColumn, it might not be set on this page
    useEffect(() => {
        const getTitle = async () => {
            if (!tableitemId || !titleColumnId) {
                return;
            }
            try {
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/table/column/${tableitemId}/${titleColumnId}`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(`[Content] getTitle response was not ok: ${data.message}`);
                }
                setFetchedTitle(data.value);
            } catch (error) {
                throw error;
            }
        };
        getTitle();
    }, [tableitemId, titleColumnId]);
    let title = fetchedTitle;
    const titleColumn = getColumnByColumnId(titleColumnId);
    if (titleColumn) {
        switch (titleColumn.columnTypeCode) {
            case 'COMPOSITE':
                {
                    try {
                        title = calculate({
                            alias: titleColumn.alias,
                            getValueByColumnName,
                            defaultValue: titleColumn.defaultValue,
                            displayFormat: titleColumn.displayFormat,
                            asString: true,
                        });
                    } catch {
                        // do nothing
                    }
                    break;
                }
            case 'SELECT':
                {
                    break;
                }
            default:
                {
                    title = titleColumn.value;
                    break;
                }
        }
    }

    const [detailViewBlocksLoaded, setDetailViewBlocksLoaded] = useState([]);
    const isEverythingLoaded = detailViewBlocksLoaded.every(({ loaded }) => loaded === true);

    useEffect(() => {
        setDetailViewBlocksLoaded(
            [...(currentDetailview.detailviewHtml.detailviewHtmlRows || [])]
                .map(({ detailviewHtmlColumns }) => detailviewHtmlColumns)
                .flat()
                .filter(({ controlPropertyId }) => controlPropertyId)
                .map(({ controlPropertyId }) => ({ loaded: false, id: controlPropertyId }))
        );
    }, [currentDetailview]);

    //Handles delete detailview click
    const handleDeleteDetailview = useCallback(() => {
        setOpendDeleteNotification(true);
    }, []);

    const registerDetailsViewBlockLoaded = useCallback((id) => {
        setDetailViewBlocksLoaded((state) => state.filter((item) => item.id !== id).concat([{ loaded: true, id: id }]));
    }, []);

    const handleControlUpdate = useCallback((columnId, val, {
        multi = false,
    } = {}) => {
        setFormValue(state => {
            console.log("handleControlUpdate - oldState", state);
            const col = state.find((column) => column.id === columnId);

            if (!col) {
                throw new Error(`No column with id ${columnId} found to update in formValue`);
            }
            const columnsDependentOnChangedColumn = state.filter((column) => column.cascadingParentColumnId === columnId);

            const updatedColumn = {
                ...col,
                [multi ? 'values' : 'value']: val
            };
            if(col.columnTypeId === ColumnType.FILE || col.columnTypeId === ColumnType.FTP ){
                if(val == null) {
                    updatedColumn.thumbnailLarge = null;
                }
            }
            let columnsWithParentChanged = [];
            if (columnsDependentOnChangedColumn != null) {
                columnsWithParentChanged = columnsDependentOnChangedColumn.map(item => { return { ...item, value: null } });
            }

            let otherColumns = state.filter((item) => item.id !== columnId && !columnsWithParentChanged.map(item => item.id).includes(item.id) /*&& item.columnTypeId !== ColumnType.FILE && item.columnTypeId !== ColumnType.FTP*/);
            //const fileColumns = state.filter(({ columnTypeId }) => columnTypeId === ColumnType.FILE || columnTypeId === ColumnType.FTP);
            //const updatedFileColumns = [...{...fileColumns, value: null, thumbnailLarge: null}];
            //const updatedFileColumns = [...fileColumns.map(fc => { return {...fc, value: null, thumbnailLarge: null}} )];
            const newState = [...otherColumns, ...columnsWithParentChanged, /*...updatedFileColumns,*/ updatedColumn];
            console.log("handleControlUpdate - newState", newState);
            return newState;
            //return [...otherColumns, ...columnsWithParentChanged, ...updatedFileColumns, updatedColumn];
        });
        setChangeInForm(true);
    }, []);

    const getFooterInfo = useCallback(async () => {
        if (!tableitemId) {
            return null;
        }
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/table/${tableId}/info/${tableitemId}`);
            const data = await res.json();
            if (data) {
                setFooterInfo(data);

            }
        } catch (error) {

            throw error;
        }
    }, [tableId, tableitemId]);

    useEffect(() => {
        getFooterInfo();
    }, [getFooterInfo]);

    useEffect(() => {
        setChangeInForm(false);
    }, [detailviewId]);

    // when navigating to a different tab, but blocked by dirty form,
    // hold the target detailviewId in memory
    const [targetDetailviewId, setTargetDetailviewId] = useState(null);
    useEffect(() => {
        setTargetDetailviewId(null);
    }, [detailviewId]);

    const transitionToDetailview = useCallback((detailviewId) => {
        push(`/detail/${detailviewId}/${tableitemId ? tableitemId : ""}`);
    }, [push, tableitemId]);

    const transitionToTab = useCallback((detailviewId) => {
        if (changeInForm) {
            setThrowNotification(true);
            setTargetDetailviewId(detailviewId);
        } else {
            transitionToDetailview(detailviewId);
        }
    }, [changeInForm, transitionToDetailview]);

    // Creating a new table
    const createTable = useCallback(async (data) => {
        const postUrl = `${appSettings.api.endpoint}/api/table/${tableId}/create`;
        let res;
        try {
            res = await msalFetch(null, postUrl, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(data),
            });
            if (!res.ok) {
                const json = await res.json();
                throw new Error(`[Content] postTable response was not ok: ${json.message}`);
            }

            toast.success(`${title || 'Item'} toegevoegd.`);
            setChangeInForm(false);
        } catch (error) {
            console.error(error);
            toast.error("Oeps er ging even iets mis...");
            return false;
        }

        const newEntry = await res.json();
        const newTableitemId = newEntry.id;

        return newTableitemId;
    }, [tableId, title]);

    const updateTable = useCallback(async (data) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/${tableId}/update`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(data),
                }
            );
            if (!res.ok) {
                const json = await res.json();
                throw new Error(`[Content] postTable response was not ok: ${json.message}`);
            }
            toast.success(`${title || 'Item'} aangepast.`);
            setChangeInForm(false);
        } catch (error) {
            console.error(error);
            toast.error("Oeps er ging even iets mis...");
            return false;
        }
        // let the save know we've completed succesfully
        return true;
    }, [tableId, title]);

    // updates the Database, determine if its a create or a post
    const saveTable = useCallback(async () => {
        setSaving(true);
        const data = {
            columns: formValue,
        };

        const fn = isCreate ? createTable : updateTable;
        const result = await fn(data);
        setSaving(false);
        return result;
    }, [isCreate, createTable, updateTable, formValue]);

    const handleSaveClick = useCallback(async () => {
        if (emptyMandatoryColumns.length > 0) {
            console.warn('Not saving because there are mandatory fields open: ', emptyMandatoryColumns.map(({ alias }) => alias).join(', '));
            setInvalidFormNotification(true);
            return false;
        } else if (nonMatchingRegexColumns.length > 0) {
            console.warn('Not saving because there are regex fields open: ', nonMatchingRegexColumns.map(({ alias }) => alias).join(', '));
            setInvalidFormNotification(true);
            return false;
        } else {
            const result = await saveTable();
            return result;

        }
    }, [saveTable, emptyMandatoryColumns, nonMatchingRegexColumns]);

    // Handles delete item
    const handleDeleteFunction = useCallback(async () => {
        const target = { columns: [formValue.find(({ primairyKey }) => primairyKey)] };

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/table/${tableId}/delete`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(target),
                }
            );

            if (res.ok) {
                toast.success("Het item is successvol verwijderd");
                setOpendDeleteNotification(false);
                handleDelete && handleDelete();
            } else {
                throw new Error('Res was not ok');
            }
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        }
    }, [formValue, tableId, handleDelete]);

    useEffect(() => {
        if (!blockHistoryOnDirty) {
            return undefined;
        }
        const unblock = history.block((location, action) => {
            // if targetDetailviewId is set and we end up here
            // the client clicked a tab and continued the UserLeave
            // so will let that pass
            if (targetDetailviewId) {
                return true;
            }
            if (changeInForm) {
                return window.confirm(`Er zijn wijzigingen, pagina verlaten?`);
            }
            return true;
        });
        return () => {
            unblock();
        };
    }, [history, changeInForm, targetDetailviewId, blockHistoryOnDirty]);

    //console.log('STATE', formValue);

    if (userLoading) {
        return <Loading />;
    } else {
        return (
            <div >
                {setTitleToHelmet && (
                    <Helmet>
                        <title>
                            {(!tableitemId && !title) ? "Toevoegen" : (title || "...")} ({currentDetailview.name})
                        </title>
                    </Helmet>
                )}
                {showTitle && (
                    <div className={`detailview-header ${!isContentForModal ? "fullscreen" : ""}`}>
                        {showTabs && !isMasterDetail && (allDetailviewData || []).length > 1 && (
                            <DetailviewTabs

                                allDetailviewData={allDetailviewData}
                                activeDetailviewId={detailviewId}
                                transitionToTab={transitionToTab}
                            />
                        )}
                        <Toolbar className="detailview-toolbar" >
                            <Item location="before" locateInMenu="never" cssClass="detailview-toolbar-item">
                                {title !== null && (<h3 title={title} className="detailview-title">{(!tableitemId && !title) ? "Toevoegen" : (`${title}` || "")}</h3>)}

                            </Item>
                            <Item location="after" locateInMenu={isMobile ? "always" : "never"} >
                                <DetailviewControlsNew
                                    table={table}
                                    handleAddNew={handleAddNew}
                                    changeInForm={changeInForm}
                                    handleSaveClick={handleSaveClick}
                                    handleSave={handleSave}
                                    handleSaveAndCreateNew={handleSaveAndCreateNew}
                                    handleClose={handleClose}
                                    saveDisabled={saveDisabled}
                                    handleDelete={handleDeleteDetailview}
                                    isMobile={isMobile}
                                    isContentForModal={isContentForModal}
                                    formValue={formValue}
                                    logging={logging}
                                    setOpenLogging={setOpenLogging}
                                    setThrowNotification={setThrowNotification}
                                    isCreate={isCreate} // for the save push to new tableitemId
                                    detailviewId={detailviewId}
                                    instructions={instructions}
                                    refreshGrid={refreshGrid}
                                    historyBackOnSave={historyBackOnSave}
                                    isMasterDetail={isMasterDetail}
                                    forceReload={forceReload}
                                />
                            </Item>
                            {!isMasterDetail && (
                                <Item location="after" locateInMenu="auto" >

                                    <DetailviewInfoControls
                                        table={table}
                                        handleAddNew={handleAddNew}
                                        changeInForm={changeInForm}
                                        handleDelete={handleDeleteDetailview}
                                        isMobile={isMobile}
                                        isContentForModal={isContentForModal}
                                        formValue={formValue}
                                        logging={logging}
                                        setOpenLogging={setOpenLogging}
                                        setThrowNotification={setThrowNotification}
                                        isCreate={isCreate} // for the save push to new tableitemId
                                        detailviewId={detailviewId}
                                        instructions={instructions}
                                        historyBackOnSave={historyBackOnSave} />
                                </Item>
                            )}
                        </Toolbar>
                    </div>
                )
                }

                <Container
                    fluid
                    className={`${!isMasterDetail ? "bl-detailview-content" : "bl-master-detail-content"}`}
                >


                    <div className={`bl-scroll-box ${isContentForModal ? "" : ""}`}>
                        <div>
                            {[...(currentDetailview.detailviewHtml.detailviewHtmlRows || [])].sort((a, b) => {
                                return a.rowNumber - b.rowNumber;
                            }).map(({ detailviewHtmlColumns }, index) => {
                                return (
                                    <Row key={index} className="bl-detail-row gx-10">
                                        {
                                            detailviewHtmlColumns.map((column, columnIndex) => {
                                                const {
                                                    columnNumber,
                                                    name,
                                                    width,
                                                    controlPropertyId,
                                                    controlRelationId,
                                                    controlImageId,
                                                    controlTabId,
                                                    controlFlowchart_Id: controlFlowchartId,
                                                    flowchartColumnId,
                                                    powerReportId
                                                } = column;
                                                
                                                if (controlPropertyId) {
                                                    return (
                                                        <DetailViewBlock
                                                            key={`property-${controlPropertyId}`}
                                                            columnIndex={columnIndex}
                                                            rowIndex={index}
                                                            width={width}
                                                            controlPropertyId={controlPropertyId}
                                                            detailviewId={detailviewId}
                                                            tableitemId={tableitemId}
                                                            tableId={tableId}
                                                            addColumnsToState={addColumnsToFormValues}
                                                            registerDetailsViewBlockLoaded={registerDetailsViewBlockLoaded}
                                                            handleControlUpdate={handleControlUpdate}
                                                            getValueByColumnId={getValueByColumnId}
                                                            getColumnByColumnId={getColumnByColumnId}
                                                            getValueByColumnName={getValueByColumnName}
                                                            parentTableitemId={parentTableitemId}
                                                            parentControlRelationId={parentControlRelationId}
                                                            allDetailviewBlocksAreLoaded={isEverythingLoaded}
                                                            changeInForm={changeInForm}
                                                            forceReload={forceReload}
                                                            forceReloadTimestamp={forceReloadTimestamp}
                                                            handleSaveClick={handleSaveClick}
                                                            isCreate={isCreate}
                                                            draggableSelectFieldId={draggableSelectFieldId}
                                                            draggableSelectFieldValue={draggableSelectFieldValue}
                                                        />
                                                    );
                                                } else if (controlImageId) {
                                                    if (isCreate) {
                                                        // we dont want to show ImageBlock, RelationBlock and TabBlock on new items
                                                        return (
                                                            <FallbackBlock
                                                                key={`relation-fallback-${controlImageId}`}
                                                                width={width}
                                                            >
                                                                Afbeeldingen worden getoond na opslaan.
                                                            </FallbackBlock>
                                                        );
                                                    }
                                                    return (
                                                        <ImageBlock
                                                            key={`image-${controlImageId}`}
                                                            name={name}
                                                            width={width}
                                                            controlImageId={controlImageId}
                                                            columnNumber={columnNumber}
                                                            detailviewId={detailviewId}
                                                            tableitemId={tableitemId}
                                                            addColumnsToState={addColumnsToFormValues}
                                                            getColumnByColumnId={getColumnByColumnId}
                                                            forceReloadTimestamp={forceReloadTimestamp}
                                                        />
                                                    );
                                                } else if (controlRelationId) {
                                                    if (isCreate) {
                                                        // we dont want to show ImageBlock, RelationBlock and TabBlock on new items
                                                        return (
                                                            <FallbackBlock
                                                                key={`relation-fallback-${controlRelationId}`}
                                                                width={width}
                                                            >
                                                                Gerelateerde informatie kan aangemaakt worden na opslaan.
                                                            </FallbackBlock>
                                                        );
                                                    }
                                                    return (
                                                        <RelationBlock
                                                            key={`relation-${controlRelationId}`}
                                                            width={width}
                                                            controlRelationId={controlRelationId}
                                                            detailviewId={detailviewId}
                                                            tableitemId={tableitemId}
                                                            isQuickCreate={isQuickCreate}
                                                            isQuickEdit={isQuickEdit}
                                                            tableId={tableId}
                                                        />
                                                    );
                                                } else if (controlTabId) {
                                                    if (isCreate) {
                                                        // we dont want to show ImageBlock, RelationBlock and TabBlock on new items
                                                        return (
                                                            <FallbackBlock
                                                                key={`relation-fallback-${controlTabId}`}
                                                                width={width}
                                                            >
                                                                Gerelateerde tab informatie kan aangemaakt worden na opslaan.
                                                            </FallbackBlock>
                                                        );
                                                    }
                                                    return (
                                                        <TabBlock
                                                            key={`tab-${controlTabId}`}
                                                            width={width}
                                                            name={name}
                                                            controlTabId={controlTabId}
                                                            detailviewId={detailviewId}
                                                            tableitemId={tableitemId}
                                                            tableId={tableId}
                                                        />
                                                    );
                                                } else if (controlFlowchartId) {
                                                    return (
                                                        <FlowchartBlock
                                                            key={`flowchart-${controlImageId}`}
                                                            name={name}
                                                            width={width}
                                                            controlFlowchartId={controlFlowchartId}
                                                            columnNumber={columnNumber}
                                                            tableitemId={tableitemId}
                                                            addColumnsToState={addColumnsToFormValues}
                                                            getColumnByColumnId={getColumnByColumnId}
                                                            handleControlUpdate={handleControlUpdate}
                                                            flowchartColumnId={flowchartColumnId}
                                                        />
                                                    )
                                                } else if (powerReportId) {
                                                    return (
                                                        <PowerReportBlock
                                                            powerReportId={powerReportId}
                                                            key={`powerReport-${powerReportId}`}
                                                            width={width}
                                                            tableItemId={tableitemId}>
                                                        </PowerReportBlock>
                                                    );
                                                } else {
                                                    console.warn('Did not render a Block in Content for column ', column);
                                                    return null;
                                                }
                                            })
                                        }
                                    </Row>
                                );
                            }) //map
                            }
                        </div>
                        {/* Page footer */}
                        {!isMasterDetail && (
                            <footer className="bl-content-footer">
                                {footerInfo && (<section className="bl-creation-info">
                                    <Tooltip
                                        target={"#tooltip_creator"}
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        hideOnOutsideClick={false}
                                    >
                                        <div className="bloom-footer-tooltip"><img src={footerInfo.creatorProfilePic ? /* 'data:image/png;base64,' + */ footerInfo.creatorProfilePic : userPlaceholder}></img>
                                        </div>
                                        <div>
                                            <strong>{footerInfo.creator}</strong><br />
                                            {footerInfo.created}
                                        </div>

                                    </Tooltip>
                                    <Tooltip
                                        target={"#tooltip_modifier"}
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        hideOnOutsideClick={false}
                                    >
                                        <div className="bloom-footer-tooltip"><img src={footerInfo.modifierProfilePic ? /* 'data:image/png;base64,' + */ footerInfo.modifierProfilePic : userPlaceholder}></img>
                                        </div>
                                        <div>
                                            <strong>{footerInfo.modifier}</strong><br />
                                            {footerInfo.modified}
                                        </div>

                                    </Tooltip>
                                    {footerInfo.created &&
                                        <p >
                                            <i className="fa-solid fa-circle-plus fa-xl bloom-footer-icon" />
                                            <img id={"tooltip_creator"} src={footerInfo.creatorProfilePic ? (/* 'data:image/png;base64,' + */ footerInfo.creatorProfilePic) : userPlaceholder}></img>

                                            {footerInfo.creator}  ({footerInfo.created})
                                        </p>
                                    }

                                    {footerInfo.modified !== footerInfo.created && (
                                        <p>
                                            <i className="fa-solid fa-pen-circle fa-xl bloom-footer-icon" />
                                            <img id={"tooltip_modifier"} src={footerInfo.modifierProfilePic ? (/* 'data:image/png;base64,' + */ footerInfo.modifierProfilePic) : userPlaceholder}></img>

                                            {footerInfo.modifier} ({footerInfo.modified})
                                        </p>
                                    )}
                                </section>)}
                                {!footerInfo && (
                                    <section className="bl-creation-info">  <p >
                                        Aangemaakt: automatisering
                                    </p>
                                    </section>)
                                }

                                {currentUser?.admin === 1 && !isMobile && (
                                    <div className="page-configurator-icon">
                                        <Link
                                            to={`/configurator/dataset/${tableId}/2/page/${detailviewId}/`}
                                        >
                                            <div title="Bewerk pagina" ><i className={"fa-regular fa-file"} /></div>
                                        </Link>
                                    </div>
                                )}
                                {currentUser?.admin === 1 && !isMobile && (
                                    <div className="page-configurator-icon">

                                        <Link
                                            to={`/configurator/dataset/${tableId}/`}
                                        >
                                            <div title="Bewerk tabel"><i className={"fa-regular fa-table"} /></div>
                                        </Link>
                                    </div>
                                )}
                                <PoweredByBloom />
                            </footer>
                        )}
                        {openDeleteNotification && (
                            <DeleteItem
                                setOpendDeleteNotification={setOpendDeleteNotification}
                                handleDeleteFunction={handleDeleteFunction}
                            />
                        )}
                        {openLogging && (
                            <LOGGING
                                setOpenLogging={setOpenLogging}
                                tableId={tableId}
                                tableitemId={tableitemId}
                                tableName={tableName}
                            />
                        )}
                    </div>
                </Container >

                {
                    throwNotification && changeInForm && (
                        <UserLeave
                            handleCancel={() => {
                                setThrowNotification(false);
                                handleUserLeaveCancel && handleUserLeaveCancel();
                            }}
                            handleSaveAndContinue={async () => {
                                if (!await handleSaveClick()) {
                                    return false;
                                }
                                return handleUserLeaveSave && handleUserLeaveSave();
                            }}
                            handleContinue={() => {
                                setThrowNotification(false);
                                targetDetailviewId && transitionToDetailview(targetDetailviewId);
                                handleUserLeaveContinue && handleUserLeaveContinue();
                                if (throwNotification === 'goBack') {
                                    history.goBack();
                                }
                            }}
                        />
                    )
                }

                {
                    invalidFormNotification && (
                        <LocalFormInValidNotification
                            emptyMandatoryColumns={emptyMandatoryColumns}
                            nonMatchingRegexColumns={nonMatchingRegexColumns}
                            onClose={() => {
                                setInvalidFormNotification(false);
                                setThrowNotification(false);
                            }}
                        />
                    )
                }
            </div >
        );
    }
};

export default Content;
