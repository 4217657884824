import React from "react";
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Detailview from "./components/detailview/Detailview";
import ListDataFetcher from "./components/list/ListDataFetcher.js";
import SchedulerDataFetcher from "./components/scheduler/SchedulerDataFetcher";
import PowerReportDataFetcher from "./components/powerReport/PowerReportDataFetcher.js"
import Settings from "./pages/settings/Settings";
import Profile from "./pages/profile/Profile";
import MenuConfig from "./components/configurator/menu/MenuConfig";
import Modal from "./components/modals/Modal";
import Configurator from "./components/configurator/Configurator";
import Datasets from "./components/configurator/datasets/Datasets";
import OrganisationSettings from "./components/configurator/organisationSettings/OrganisationSettings";
import Dataset from "./components/configurator/dataset/Dataset";
import MenuSingle from "./components/configurator/menu/MenuSingle";
import UserLeaveConfirmation from "./components/notifications/userLeaves/UserLeaveConfirmation";
import { useModalContext } from "./context/modalContext";
import DeleteNotification from "./components/notifications/DeleteNotification";
import { useGlobalContext } from "./context/context";
import FormInValidNotification from "./components/notifications/FormInValidNotification.js";
import DeleteProceedNotification from "./components/notifications/DeleteProceedNotification";
import Search from "./components/configurator/search/Search";
import Users from "./components/configurator/users/Users";
import Roles from "./components/configurator/roles/Roles";
import Role from "./components/configurator/roles/Role";
import ReportDesigner from "./components/configurator/reports/ReportDesigner";
import IndexConnectors from "./components/configurator/connectors/indexConnectors.js"
import IndexPowerReport from "./components/configurator/dashboards/indexPowerReports.js"
import Forbidden from "./components/notifications/forbidden/Forbidden";
import Meldingen from "./components/meldingen/Meldingen";
import News from "./components/news/News";
import ActionLogs from "./components/Action/ActionLog";
import Dashboard from "./pages/dashboard/Dashboard";
import SaveChangesNotification from "./components/notifications/SaveChangesNotification";
import FallbackComponent from "./components/ErrorHandling/FallbackComponent";
import NotFound from "./components/ErrorHandling/NotFound";
import Logout from "./components/Logout";
import Register from "./pages/Register";
import { useGraphContext } from "./components/graph/context";
import Layout from './components/layout/layout';

const AdminRoute = ({ children, ...props }) => {
    const {
        userLoading,
        currentUser,
    } = useGraphContext();
    if (userLoading || !currentUser) {
        return null;
    }

    if (currentUser?.admin !== 1 && !currentUser.overruleAdmin) {
        return (
            <Redirect
                to={{
                    pathname: "/forbidden",
                }}
            />
        )
    }
    return (
        <Route {...props} />
    );
}

const Routes = () => {
    const {
        deleteNotification,
        formInValidNotification,
        deleteProceedNotification,
        userLeaveNotification,
        saveChangesNotification,
    } = useModalContext();
    const { isMobile } = useGlobalContext();

    document.onmouseover = function () {
        //User's mouse is inside the page.
        window.innerDocClick = true;
    };

    document.onmouseleave = function () {
        //User's mouse has left the page.
        window.innerDocClick = false;
    };

    window.onhashchange = function () {
        if (window.innerDocClick) {
            //Your own in-page mechanism triggered the hash change
        } else {
            window.location.reload();
        }
    };

    return (
        <>
            {/* Notifications go here */}
            {deleteNotification && <DeleteNotification />}
            {deleteProceedNotification && <DeleteProceedNotification />}
            {formInValidNotification && <FormInValidNotification />}
            {userLeaveNotification && <UserLeaveConfirmation />}
            {saveChangesNotification && <SaveChangesNotification />}
            <Modal />

            <Layout>
                {/* {isMobile ? <MobileMenu /> : <Menu />}
                {!isMobile && <UserNav />}
                {isMobile && <MobileMenuList />} */}

                <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/registreer" component={Register} />
                    <Route path="/overview/:overviewId" exact component={ListDataFetcher} />
                    <Route path="/scheduler/:schedulerId" exact component={SchedulerDataFetcher} />
                    <Route exact path="/detail/:detailviewId/" component={Detailview} />
                    <Route path="/powerreport/:powerReportId" component={PowerReportDataFetcher} />
                    <Route
                        exact
                        path="/detail/:detailviewId/:tableitemId/"
                        component={Detailview}
                    />

                    <Route path="/profiel" component={Profile} />
                    <Route path="/instellingen" component={Settings} />

                    {/* configurator routes */}
                    <AdminRoute
                        exact
                        path="/configurator"
                        component={isMobile ? Dashboard : Configurator}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/datasets"
                        component={isMobile ? Dashboard : Datasets}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/dataset/:id"
                        component={isMobile ? Dashboard : Dataset}
                    />

                    <AdminRoute
                        exact
                        path="/configurator/dataset/:id/:activeGrid/list/:activeList"
                        component={isMobile ? Dashboard : Dataset}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/dataset/:id/:activeGrid/set/:activeSet"
                        component={isMobile ? Dashboard : Dataset}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/dataset/:id/:activeGrid/page/:activePage"
                        component={isMobile ? Dashboard : Dataset}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/dataset/:id/:activeGrid"
                        component={isMobile ? Dashboard : Dataset}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/menu"
                        component={isMobile ? Dashboard : MenuConfig}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/menuitem/:id"
                        component={isMobile ? Dashboard : MenuSingle}
                    />
                    <AdminRoute
                        path="/configurator/search"
                        component={isMobile ? Dashboard : Search}
                    />
                    <AdminRoute
                        path="/configurator/users"
                        component={isMobile ? Dashboard : Users}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/roles"
                        component={isMobile ? Dashboard : Roles}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/organisationSettings"
                        component={isMobile ? Dashboard : OrganisationSettings}
                    />
                    <AdminRoute
                        exact
                        path="/configurator/roles/:id"
                        component={isMobile ? Dashboard : Role}
                    />

                    <AdminRoute path="/reports" component={ReportDesigner} />
                    <AdminRoute path="/notifications" component={Meldingen} />
                    <AdminRoute path="/configurator/connectors" component={IndexConnectors} />
                    <AdminRoute path="/configurator/dashboards" component={IndexPowerReport} />
                    <Route path="/news" component={News} />
                    <Route path="/actions" component={ActionLogs} />
                    <Route path="/forbidden" component={Forbidden} />
                    <Route path="/error" component={FallbackComponent} />
                    <Route path="/logout" component={Logout} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </>
    );
};

export default Routes;
