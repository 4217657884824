import { msalInstance } from "../index";
import { InteractionRequiredAuthError, BrowserAuthError } from "@azure/msal-browser";
import { graphConfig } from "../msalConfig";

export const triggerMsalLogin = async () => {
    const loginRequest = {
        scopes: ["User.Read"],
        redirectUri: '/',
    };
    if(process.env.REACT_APP_MSAL_LOGIN_METHOD === 'redirect') {
        await msalInstance.loginRedirect(loginRequest);
    } else {
        await msalInstance.loginPopup(loginRequest);
    }
    if (process.env.REACT_APP_MSAL_LOGIN_REFRESH) {
        window.location.reload();
    }
};

const apiScope = `${process.env.REACT_APP_DEFAULT_API_AUDIENCE}/user_impersonation`;

export const acquireTokenRequest = async (scopes = [apiScope]) => {
    const loginRequest = {
        scopes,
    };
    const request = {
        ...loginRequest,
        account: msalInstance.getActiveAccount(),
    };
    let response;
    try {
        response = await msalInstance.acquireTokenSilent(request);
    } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
            try {
                response = await msalInstance.acquireTokenPopup(request);
            } catch (e) {
                if (e instanceof InteractionRequiredAuthError) {
                    try {
                        response = await msalInstance.acquireTokenRedirect(request);
                    } catch (finalError) {
                        console.error('We tried all token methods, none worked :(');
                        throw finalError;
                    }
                }
            }
        }
        if (err instanceof BrowserAuthError) {
            console.error('We are in a BrowserAuthError. Probably simply not logged in. Trigger a login?');
        }
        throw err;
    }
    return response;
}

export const graphMeFetch = async (endpoint) => {
    const response = await acquireTokenRequest(['User.Read']);
    const bearerString = response.accessToken;
    if (!bearerString) {
        throw new Error(`Did not find accessToken in acquireTokenRequest response, fetching graphMeFetch ${endpoint}`);
    }
    const bearer = `Bearer ${bearerString}`;

    const options = {
        headers: {
            Authorization: bearer,
        },
    };

    return fetch(graphConfig.graphMeEndpoint + endpoint, options);
};

const msalFetch = async (
    _,
    url,
    options = {},
) => {
    const response = await acquireTokenRequest();
    const bearerString = response.accessToken;
    if (!bearerString) {
        throw new Error(`Did not find accessToken in acquireTokenRequest response, fetching ${url}`);
    }
    const bearer = `Bearer ${bearerString}`;

    options = {
        ...options,
        headers: {
            ...(options.headers || {}),
            Authorization: bearer,
        }
    }

    return fetch(url, options);
};

export default msalFetch;
