import React, { useState, useCallback, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../../appsettings.js";
import toast from "react-hot-toast";
import {
    DataGrid,
    Column,
    Editing,
    Paging,
    FilterRow,
    Pager
} from "devextreme-react/data-grid";


const RoleQueryVisual = ({ roleId }) => {
    console.log("RoleScheduler - RoleID - Initialize", roleId);
    const loadQueryVisuals = useCallback(async (loadOptions) => {
        console.log("loadSchedulers", roleId, loadOptions)

        if (roleId == null)
            return;
        const url = `${appSettings.api.endpoint}/api/configurator/power-reports-by-role/${roleId}`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        console.log("queryVisualsByRole - JSON", json);
        return json;
    }, [roleId]);

    const updateServerRecord = useCallback(async (rec, val) => {
        console.log("RoleQueryVisual - updateServerRecord - rec - val", rec, val);
        try {
            let body = {
                roleId: roleId,
                queryVisualId: rec.id,
                value: val[Object.keys(val)[0]]
            };

            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/role/update-power-report-visibility/${roleId}/${body.queryVisualId}/${body.value}/`
            );
            console.log("Scheduler updateServerRecord - res", res);
            toast.success("Toegang bijgewerkt");
        } catch (error) {
            toast.error("error");
            throw error;
        }
    }, [roleId])

    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            load: loadQueryVisuals,
            update: updateServerRecord,
            //remove: deleteServerRecord,
        })
    }), [loadQueryVisuals, updateServerRecord]);
    return <div>
        <DataGrid
            dataSource={dataSource}
            showColumnLines={false}
            rowAlternationEnabled
            showRowLines={true}
            noDataText="Klik op een rol om de rechten in te stellen"
            height={"70vh"}
        >
            <Paging defaultPageSize={25} />
            <Pager
                visible={true}
                showPageSizeSelector={true}
                showInfo={true}
                allowedPageSizes={[25, "all"]}
                showNavigationButtons={true}
            />
            <FilterRow
                visible={true}
                applyFilter={'auto'} />
            <Editing
                mode="cell"
                allowUpdating={true}
                refreshMode={"repaint"}

            />
            <Column
                allowEditing={false}
                caption="Globaal overzicht"
                dataField="queryVisualTitle"
                dataType="string"
                defaultSortOrder="asc"
                sortIndex={2}
                allowSorting={false}
            />
            <Column
                allowEditing={true}
                caption="Zichtbaar"
                dataType="boolean"
                dataField="hasRights"
            />

        </DataGrid></div>
}
export default RoleQueryVisual;