import PowerReport from "./PowerReport";
import React, { useState, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import UpsertQueryVisual from "../../components/configurator/dashboards/upsertPowerReport.js";

const PowerReportWrapper = ({
    dataSource,
    showChart,
    showFieldChooser,
    title,
    powerReportId,
    refreshPowerReport,
    isDetailviewObject
}) => {
    const [showPowerReportConfig, setShowPowerReportConfig] = useState();

    const onConfigurePowerReportClick = useCallback(async (e) => {
        setShowPowerReportConfig(true);
    }, [setShowPowerReportConfig]);

    const closeFunction = useCallback(async (e) => {
        setShowPowerReportConfig(false);
        refreshPowerReport();
    }, [refreshPowerReport]);

    return (
        <>
            <Helmet>
                <title>
                    {!isDetailviewObject && `${title}`}
                </title>
            </Helmet>

            <section className={`bl-overview ${!isDetailviewObject ? 'bl-card' : ''}`} >
                <PowerReport
                    showChart={showChart}
                    showFieldChooser={showFieldChooser}
                    dataSource={dataSource}
                    title={title}
                />
            </section>
            <div title="Bewerk lijst" className="control-configurator-icon"><i className={"fa-regular fa-list"} onClick={onConfigurePowerReportClick} /></div>
            {showPowerReportConfig && <UpsertQueryVisual
                showPopup={closeFunction}
                editQueryVisualId={powerReportId}
            //closeFunction={closeFunction}
            />}
        </>)
};
export default PowerReportWrapper