import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, TextArea } from "devextreme-react";
import { Popup } from 'devextreme-react/popup';
import React, { useState, useCallback, useEffect, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings.js";
import DataGrid, {
    Column,
    Editing,
    Lookup as DatagridLookup,
    RowDragging,
    Button
} from "devextreme-react/data-grid";
import { useGraphContext } from "../../graph/context";

import { format } from 'sql-formatter';
import TabPanel from 'devextreme-react/tab-panel';
import { Button as QueryButton } from 'devextreme-react/button';

const UpsertQueryVisual = ({
    editQueryVisualId,
    showPopup,
}) => {
    const [formData, setFormData] = useState(null);
    const [detailviewArray, setDetailviewArray] = useState(null);
    // const [enableSubmit, setEnableSubmit] = useState(true);
    const [columnSummaryTypeArray, setColumnSummaryTypeArray] = useState(null);
    const [columnDataTypeArray, setColumnDataTypeArray] = useState(null);
    const [columnAreaTypeArray, setColumnAreaTypeArray] = useState();
    const [columnNamesWithoutId, setColumnNamesWithoutId] = useState([]);
    const [columnNamesWithId, setColumnNamesWithId] = useState([]);
    const [tempQuery, setTempQuery] = useState(''); // Separate state for textarea input

    const { currentUser } = useGraphContext();
    const isInsert = editQueryVisualId == null;
    // console.log("UpsertQueryVisual - enableSubmit", enableSubmit);

    const defaultFormData = useMemo(() => {
        return {
            title: '',
            query: '',
            showChart: false,
            showPivotGrid: true,
            showFieldChooser: true,
            columns: [],
        }
    }, []);

    const insert = useCallback(async (e) => {
        let newData = { ...formData, query: tempQuery };
        newData.columns.forEach(cd => cd.id = null);
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/create`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(newData),
                }
            );
            toast.success(`Power report '${formData.title}' aangemaakt`)
            showPopup(false);
        } catch (error) {
            throw error;
        }
    }, [formData, showPopup, tempQuery]);

    const edit = useCallback(async (e) => {
        let newData = { ...formData, query: tempQuery };
        newData.columns.forEach(cd => cd.id = null);
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/edit`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(newData),
                }
            );
            toast.success(`Power report '${formData.title}' bijgewerkt`);
            showPopup(false);
        } catch (error) {
            throw error;
        }
    }, [formData, showPopup, tempQuery]);

    const handleSave = useCallback(async (e) => {
        if (isInsert) {
            await insert(e);
        } else {
            await edit(e);
        }
    }, [isInsert, insert, edit]);

    const handleInputChange = (name) => (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: e.value,
        }));
    };

    const handleQueryChange = useCallback((e) => {
        setTempQuery(e.value);
    }, []);

    const onHiding = useCallback(() => { showPopup(false); }, [showPopup]);

    useEffect(() => {
        const initializeQueryVisual = async () => {
            if (isInsert) {
                setFormData(defaultFormData);
            } else {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get/${editQueryVisualId}`);
                const json = await res.json();

                setFormData(json);
                setTempQuery(json.query);
            }
        }
        initializeQueryVisual();

    }, [defaultFormData, isInsert, editQueryVisualId]);

    useEffect(() => {
        const loadDetailviews = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/detailview/list/${currentUser?.organisation.id}`);
            const json = await res.json();
            const detailViews = json.map(item => { return { name: item.table.tableName + ' - ' + item.name, id: item.id, tableName: item.table.tableName } });
            setDetailviewArray(detailViews);
        };
        loadDetailviews();
    }, [currentUser?.organisation.id]);

    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-column-summary-types`);
            const json = await res.json();
            setColumnSummaryTypeArray(json);
        };
        internalUseEffect();
    }, [])

    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-data-types`);
            const json = await res.json();
            setColumnDataTypeArray(json);
        };
        internalUseEffect();
    }, [])


    useEffect(() => {
        const internalUseEffect = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-area-types`);
            const json = await res.json();
            setColumnAreaTypeArray(json);
        };
        internalUseEffect();
    }, [])

    const onEditingStart = useCallback(() => {
        //setEnableSubmit(false);
    }, [])

    const onEditCanceled = useCallback(() => {
        // setEnableSubmit(true);
    }, []);

    const onRowUpdated = useCallback(() => {
        // setEnableSubmit(true);
    }, [])

    const updateGridOrder = useCallback((area) => (e) => {
        const newIndex = e.toIndex;
        const oldIndex = e.fromIndex;
        const array = [...formData.columns];

        // Move the record in formData array based on newIndex
        const movedRecord = array.splice(oldIndex, 1)[0]; // Remove the record from the old position
        array.splice(newIndex, 0, movedRecord);
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        const result = { ...formData, columns: array }

        setFormData(result);
    }, [formData]);


    const onInitNewRow = useCallback(async () => {
        console.log("onInitNewRow");
        //setTimeout(() => setEnableSubmit((previousValue) => !previousValue), 1000);
        //setEnableSubmit((previousValue) => !previousValue);
    }, []);

    const onRowInserted = useCallback((area) => (e) => {
        let result = { ...formData };
        let array = formData.columns;

        array.forEach((item, index) => { item.seqNr = index; /*item.columnAreaId = columnAreaId*/ }); // Insert it at the new position
        result.columns = array;
        //setEnableSubmit(true);
    }, [formData])

    const onRowRemoved = useCallback((area) => (e) => {
        let result = { ...formData };
        let array = formData.columns;
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        result.columns = array;
    }, [formData]);

    const onFormatQueryClick = useCallback(() => {
        setTempQuery((previousValue) => {
            let formattedQuery = format(previousValue, { language: 'transactsql' });
            formattedQuery = formattedQuery.replace(/\n/g, '\n ');
            return formattedQuery;
        });
        toast.success("De query is bijgewerkt");
    }, []);

    const fetchColumnsFromQuery = useCallback(async () => {
        if (formData?.query == null)
            return;
        const res = await msalFetch(null, `${appSettings.api.endpoint}/api/power-report/get-column-names-from-query/`,
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({ query: formData?.query }),
            }
        );
        if (!res.ok) {
            toast.error("Er is een fout opgetreden. Controleer de query.")
            return;
        }
        const json = await res.json();
        const columnNamesWithId = json.filter(column => column.toLowerCase().endsWith('_id'));
        const columnNamesWithoutId = json.filter(column => !column.toLowerCase().endsWith('_id'))

        setColumnNamesWithId(columnNamesWithId);
        setColumnNamesWithoutId(columnNamesWithoutId);
        setFormData((previousFormData) => {
            const columns = previousFormData.columns.map(column => {
                const fixedColumn = {
                    ...column,
                    datafield: columnNamesWithoutId.includes(column.datafield) ? column.datafield : null,
                    keyPropertyName: columnNamesWithId.includes(column.keyPropertyName) ? column.keyPropertyName : null
                };
                return fixedColumn;
            });
            //const newFormData = { ...formData, columns };
            return { ...previousFormData, columns };
        });

        //setFormData(newFormData);
    }, [formData?.query]);

    const onGetColumnsFromQueryClick = useCallback(async () => {
        setFormData((previousFormData) => { return { ...previousFormData, query: tempQuery } })
        await fetchColumnsFromQuery()
    }, [fetchColumnsFromQuery, tempQuery]);

    useEffect(() => {
        const innerUseEffect = async () => {
            await fetchColumnsFromQuery();
        };
        innerUseEffect();
    }, [fetchColumnsFromQuery])

    //Tabs parts
    const tabs2 = [
        {
            icon: 'fa-solid fa-database',
            title: 'Query',
        },
        {
            icon: 'fas fa-table config',
            title: 'Draaitabel',
        },

    ];
    const [selectedTabIndex, setSelectedTabIndex] = useState(null);

    const itemComponent = ({ value }) => {
        return (
            <div>
                {selectedTabIndex === 0 &&
                    (
                        <Col lg="12">

                            <h3 className="configheader">SQL-query</h3>
                            <QueryButton
                                className="configheader configbutton"
                                stylingMode='contained'
                                type="default"
                                hint='Query opschonen'
                                icon={'fa-solid fa-line-height'}
                                onClick={onFormatQueryClick}


                            />
                            <QueryButton
                                className="configheader configbutton"
                                stylingMode='contained'
                                type="default"
                                hint='Kolommen ophalen en controle query'
                                icon={'fa-solid fa-database'}
                                onClick={onGetColumnsFromQueryClick}


                            />

                            <TextArea
                                value={tempQuery}
                                valueChangeEvent="change"
                                minHeight={"50vh"}
                                autoResizeEnabled={true}
                                onValueChanged={/*handleInputChange('query')*/handleQueryChange}
                            />
                            <ul>
                                <li>Om dit report ook op een pagina te tonen voeg bijv ' AND (@tableItemId is null OR Relaties.Id = @tableItemId)' toe.</li>
                                <li>Voor een link naar een pagina met recordID, gebruik <em>*_id</em> aan het einde, (bijv. relatie_id, offerte_id, etc)</li>
                            </ul>

                        </Col>

                    )
                }
                {selectedTabIndex === 1 &&
                    (
                        <Col lg="12">
                            <h3 className="configheader">Inrichting</h3>
                            <CheckBox
                                className="configlabel"
                                text="Toon draaitabel"
                                value={formData.showPivotGrid}
                                onValueChanged={handleInputChange('showPivotGrid')}
                                readOnly={true}
                            />

                            <CheckBox
                                className="configlabel"
                                text="Toon grafiek"
                                value={formData.showChart}
                                onValueChanged={handleInputChange('showChart')}
                            />

                            <CheckBox
                                className="configlabel"
                                text="Toon kolomkiezer"
                                value={formData.showFieldChooser}
                                onValueChanged={handleInputChange('showFieldChooser')}
                            />
                            <DataGrid
                                dataSource={formData.columns}
                                showBorders={true}
                                hoverStateEnabled={true}
                                showRowLines={false}
                                showColumnLines={true}
                                rowAlternationEnabled={true}
                                keyExpr="id"
                                width="auto"
                                onRowInserted={onRowInserted('row')}
                                onRowRemoved={onRowRemoved('row')}
                                onEditingStart={onEditingStart}
                                onEditCanceled={onEditCanceled}
                                onRowUpdated={onRowUpdated}
                                onInitNewRow={onInitNewRow}
                            >

                                <Column caption="Veld" dataField="datafield">
                                    <DatagridLookup
                                        dataSource={columnNamesWithoutId}
                                    />
                                </Column>
                                <Column caption="Gebied" dataField="columnAreaId" >
                                    <DatagridLookup
                                        dataSource={columnAreaTypeArray}
                                        valueExpr="id"
                                        displayExpr="name"

                                    />
                                </Column>
                                <Column caption="Aggregatie" dataField="columnSummaryTypeId" >
                                    <DatagridLookup
                                        dataSource={columnSummaryTypeArray}
                                        valueExpr="id"
                                        displayExpr="name"

                                    />
                                </Column>
                                <Column caption="Weergave" dataField="columnDataTypeId" >
                                    <DatagridLookup
                                        dataSource={columnDataTypeArray}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column caption="Paginalink" dataField="detailviewId">
                                    <DatagridLookup
                                        allowClearing={true}
                                        dataSource={detailviewArray}
                                        valueExpr="id"
                                        displayExpr="name"
                                        group="tableName"

                                    />
                                </Column>
                                <Column caption="PaginaID" dataField="keyPropertyName">
                                    <DatagridLookup
                                        allowClearing={true}
                                        dataSource={columnNamesWithId}
                                    /></Column>
                                <Column
                                    caption="Breedte"
                                    dataType="number"
                                    defaultV
                                    dataField="width"
                                />
                                <Column type="buttons" caption="Acties" width={60}>
                                    <Button name="edit" />
                                    <Button name="delete" />
                                </Column>
                                <Editing
                                    mode="row"
                                    allowUpdating={true}
                                    allowAdding={true}
                                    allowDeleting={true}
                                    useIcons={true}
                                />
                                <RowDragging
                                    allowReordering={true}
                                    onReorder={updateGridOrder('row')}
                                    showDragIcons={true}
                                    autoScroll={true} />
                            </DataGrid>
                        </Col>
                    )
                }

            </div>
        );
    };


    return (<Popup
        className="bl-popup"
        showTitle={true}
        animation={false}
        hideOnOutsideClick={true}
        title={`${isInsert ? 'Toevoegen' : 'Bijwerken'} queryvisual`}
        resizeEnabled={true}
        visible={true}
        width={"90vw"}
        showCloseButton={true}
        onHiding={onHiding}
        // fullScreen={true}
        toolbarItems={[
            {
                toolbar: "bottom",
                widget: "dxButton",
                location: "after",
                options:
                {
                    icon: 'check',
                    //disabled: !enableSubmit,
                    text: 'Opslaan en sluiten',
                    useSubmitBehavior: true,
                    onClick: handleSave
                }


            }]}
    >
        {formData != null && <>
            <Row>
                <Col lg="3">
                    <h3 className="configheader">Titel</h3>
                </Col>
                <Col lg="9">
                    <TextBox
                        className="configheader"
                        type="input"
                        value={formData.title}
                        valueChangeEvent="keyup"
                        onValueChanged={handleInputChange('title')}
                    />

                </Col>
            </Row>
            <Row>
                <TabPanel
                    // className="bloom-tabpanel"
                    dataSource={tabs2}
                    animationEnabled={true}
                    swipeEnabled={true}
                    defaultSelectedIndex={0}
                    tabsPosition={"left"}
                    iconPosition={"top"}
                    selectedIndex={selectedTabIndex}
                    onOptionChanged={(e) => {
                        if (e.name === 'selectedIndex') {

                            setSelectedTabIndex(e.value);
                        }
                    }}
                    width="100%"

                    stylingMode={'primary'}

                    itemComponent={itemComponent}
                >
                </TabPanel>
            </Row>








        </>}
        {!formData && <div>Laden..</div>}
    </Popup >)
}

export default UpsertQueryVisual;