import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";

import { SelectBox, TextBox } from "devextreme-react";

import Loading from "../../loading/Loading";
import BlModalFooter from "../BlModalFooter";
import Form from "devextreme-react/form";

const NEWMENUCONFIG = () => {
    const msal = useMsal();

    const [loading, setLoading] = useState(true);
    const [eventButtons, setEventButtons] = useState(true);

    const [selectOverzicht, setSelectOverzicht] = useState([]);

    const [post, setPost] = useState({
        Name: "",
        Icon: "",
        Overview: "",
    });

    let menuList = [];
    let overzicht = [];

    const formChangeHandler = (e) => {
        setEventButtons(false);
    };
    const submitHanler = (e) => {
        e.preventDefault();
        console.log(post);
    };

    const getData = () => {
        setLoading(true);
        msalFetch(msal, `${appSettings.api.endpoint}/api/Menu`)
            .then((response) => response.json())
            .then((data) => {
                data.menus.map((item, index) => {
                    const { icon, name, menuItems, id } = item;

                    menuList = [
                        ...menuList,
                        {
                            items: menuItems,
                            id: index,
                            menuName: name,
                        },
                    ];

                    return menuList;
                });

                menuList.map((item) => {
                    const { menuName, id, items } = item;
                    items.map((submenuItem, index) => {
                        const { name, menuId } = submenuItem;
                        overzicht = [
                            ...overzicht,

                            {
                                name: name,
                                menuId: id,
                                overzichtName: menuName,
                                label: `${name} (${menuName})`,
                            },
                        ];
                        return overzicht;
                    });

                    return overzicht;
                });

                setSelectOverzicht(overzicht);
                setLoading(false);
                return selectOverzicht;
            })
            .catch(() => {
                setLoading(false);
                throw "Data Loading Error";
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const { Name, Icon, Overview } = post;

    if (!loading) {
        return (
            <div className="bl-modal-inner">
                <Form name="menuItemFrom" onSubmit={submitHanler} />
                <section className="bl-card">
                    <h3>Menu item</h3>
                    <div className="dx-field">
                        <div className="dx-field-label">aOverzicht</div>
                        <div className="dx-field-value">
                            <SelectBox
                                searchEnabled
                                dataSource={selectOverzicht}
                                displayExpr="label"
                                valueExpr={Overview}
                                // defaultValue={option ? option.value : ""}
                                placeholder="Maak een keuze"
                                // onValueChanged={formChangeHandler}
                                name="Overview"
                            />
                        </div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Menu naam</div>
                        <div className="dx-field-value">
                            <TextBox
                                placeholder="Menu naam..."
                                onValueChanged={formChangeHandler}
                                valueExpr={Name}
                                name="Name"
                            />
                        </div>
                    </div>

                    <div className="dx-field">
                        <div className="dx-field-label">Menu-icoon</div>
                        <div className="dx-field-value">
                            <TextBox
                                placeholder="fa-comment"
                                onValueChanged={formChangeHandler}
                                valueExpr={Icon}
                                name="Icon"
                            />
                        </div>
                    </div>
                </section>

                <BlModalFooter eventButtons={eventButtons} />
            </div>
        );
    } else {
        return <Loading />;
    }
};

export default NEWMENUCONFIG;
