import React, { useState, useEffect, useCallback, useMemo } from "react";
import CustomStore from "devextreme/data/custom_store";
import appSettings from "../../../appsettings.js"
import msalFetch from "../../../api/MsalFetch.js";
import DataGrid, {
    Column,
    Button,// as DataGridButton,
    Toolbar,
    Item,
    Editing,
} from "devextreme-react/data-grid";
import { Button as ToolbarButton } from 'devextreme-react/button';
import toast from "react-hot-toast";
import UpsertQueryVisual from "./upsertPowerReport.js";
import { Helmet } from "react-helmet";
import PopupPowerReport from "./PopupPowerReport.js";

const IndexPowerReport = () => {
    const [showInsertQueryVisual, setShowInsertQueryVisual] = useState(false);
    const [showEditQueryVisual, setShowEditQueryVisual] = useState(false);
    const [editQueryVisualId, setEditQueryVisualId] = useState(null);
    //const [showPowerReport, setShowPowerReport] = useState(false);
    const [popupPowerReportId, setPopupPowerReportId] = useState();

    const load = useCallback(async (loadOptions) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/index`);

            const data = await res.json();
            console.log("load", data);
            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const remove = useCallback(async (e) => {
        try {
            console.log(e);
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/power-report/delete/${e}`,
                {
                    method: "DELETE",
                    headers: { "Content-type": "application/json" }
                }
            );
            //const json = await res.json();
            if (!res.ok) {
                throw new Error();
            }
            toast.success('De draaitabel is verwijderd')
        } catch (error) {
            toast.error("Error bij verwijderen draaitabel");
        }
    }, [])

    const onAddQueryVisual = useCallback(async (e) => {
        // e.event.preventDefault();
        // const url = '/configurator/power-reports/add'

        // push(url);
        console.log("onAddQueryVisual");
        setShowInsertQueryVisual(true);
    }, []);

    const onEditingStart = useCallback(async (e) => {
        console.log(e);
        e.cancel = true;
        setShowEditQueryVisual(true);
        setEditQueryVisualId(e.key);
    }, [])

    const dataStore = useMemo(() => {
        return new CustomStore({
            key: 'id',
            load: load,
            remove: remove
        });
    }, [load, remove]);

    const openPowerReport = useCallback(async (e) => {
        console.log("openPowerReport", e);
        setPopupPowerReportId(e.row.key);
    }, []);

    const closePowerReport = useCallback(async () => {
        setPopupPowerReportId(null);
    }, [setPopupPowerReportId]);

    return (<>
        <Helmet>
            <title>
                {`Dashboards`}
            </title>
        </Helmet>

        <section className={`bl-overview bl-card`} >

            <DataGrid
                dataSource={dataStore}
                title="Queryvisuals"
                showBorders={true}
                hoverStateEnabled={true}
                showRowLines={false}
                showColumnLines={true}
                id="queryVisualId"
                rowAlternationEnabled={true}
                onEditingStart={onEditingStart}
                onRowClick={onEditingStart}
            >
                <Toolbar>

                    <Item location="before">
                        <h3>{`Dashboards`}</h3>
                    </Item>
                    <Item location="after">
                        <ToolbarButton
                            icon="fas fa-plus"
                            onClick={onAddQueryVisual}
                        />
                    </Item>
                </Toolbar>
                <Column
                    caption="Naam"
                    dataField="title"
                />
                <Column
                    caption="Toon draaitabel"
                    dataType="boolean"
                    dataField="showPivotGrid"
                />
                <Column
                    caption="Toon grafiek"
                    dataType="boolean"
                    dataField="showChart"
                />
                <Column
                    caption="Toon veldenkiezer"
                    dataType="boolean"
                    dataField="showFieldChooser"
                />
                <Column type="buttons">
                    <Button name="edit" />  {/* Edit button */}
                    <Button name="delete" />  {/* Delete button */}

                    {/* Custom Button */}
                    <Button
                        hint="Bekijk voorbeeld"
                        icon="fas fa-file"
                        onClick={openPowerReport}
                    />
                </Column>
                <Editing
                    mode="row"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={true}
                    useIcons={true}
                />
            </DataGrid>
        </section>
        {/* {showInsertQueryVisual && <Popup>
            <UpsertQueryVisual />
        </Popup>} */}
        {showInsertQueryVisual && <UpsertQueryVisual
            showPopup={setShowInsertQueryVisual}
        />}
        {showEditQueryVisual && <UpsertQueryVisual
            showPopup={setShowEditQueryVisual}
            editQueryVisualId={editQueryVisualId}

        />}
        {popupPowerReportId && <PopupPowerReport
            powerReportId={popupPowerReportId}
            closePowerReport={closePowerReport}
        />}
    </>)
}

export default IndexPowerReport;